import { instance } from '@/utils/http.js'

export const loginApi = (data) => {
  return instance({
    url: '/api/v1/login',
    method: 'POST',
    data
  })
}

export const logoutApi = () => {
  return instance({
    url: '/api/v1/user/logout',
    method: 'get',

  })
}


export const registerApi = (data) => {
  return instance({
    url: '/api/v1/user/register',
    method: 'POST',
    data
  })
}


